import { MAINTAIN_PERMISSION, STERILIZATION_SPECIFICATIONS_HYDROSECTIONTIMES } from "@kraftheinz/common";
import { n as normalizeComponent } from "./index.js";
import "lodash";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("edit-modal-composer", { attrs: { "title": "Edit Hydro Section Times" } }, [_c("select-input", { key: "cookerCapacityId", attrs: { "label": "Cooker", "reference": "sterilization.common.cooker-capacities", "rules": "required", "source": "id", "source-label": "cookerNo", "span": 24, "disabled": !_vm.hasMaintainPermission } }), _c("select-input", { key: "chain", attrs: { "label": "Chain", "data-source": _vm.arrChain, "rules": "required", "source": "source", "source-label": "sourceLabel", "span": 24, "disabled": !_vm.hasMaintainPermission } }), _c("select-input", { key: "hydroSectionId", attrs: { "label": "Section", "reference": "sterilization.common.hydro-sections", "rules": "required", "source": "id", "source-label": "description", "span": 24, "disabled": !_vm.hasMaintainPermission } }), _c("text-input", { key: "flightCount", attrs: { "label": "Flight Count", "rules": "required", "span": 24, "disabled": !_vm.hasMaintainPermission } })], 1);
};
var staticRenderFns$1 = [];
const __vue2_script$1 = {
  name: "EditFillTypes",
  inject: ["can"],
  data() {
    return {
      arrChain: [
        {
          source: "I",
          sourceLabel: "Inner"
        },
        {
          source: "O",
          sourceLabel: "Outer"
        }
      ],
      permissions: {
        maintain: MAINTAIN_PERMISSION
      }
    };
  },
  computed: {
    hasMaintainPermission() {
      return this.can(this.permissions.maintain);
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, null, null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var EditCookerFlight = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "edit-cooker-flights" }, [_c("resource", { attrs: { "page": _vm.page, "name": "sterilization.cooker-flights", "api-url": _vm.apiUrl, "edit": _vm.EditCookerFlight, "redirect-route": "/sterilization-specifications/hydro-section-times" } }), _c("resource", { attrs: { "name": "sterilization.common.cooker-capacities", "api-url": _vm.apiUrl } }), _c("resource", { attrs: { "name": "sterilization.common.hydro-sections", "api-url": _vm.apiUrl } })], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  name: "EditCookerFlight",
  data() {
    return {
      EditCookerFlight,
      page: STERILIZATION_SPECIFICATIONS_HYDROSECTIONTIMES,
      apiUrl
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
